<template>
	<div class="activeList">
		<div class="el-content mb12">
			<div class="tips">
				<p>1、请先添加活动的基本信息，然后在列表操作栏中再添加票种信息、报名需要填写的信息</p>
				<p>2、报名需要填写的信息默认为 姓名、电话，请勿重复添加</p>
				<p>3、报名通道开启、并且活动上架后方可暂时在客户端进行展示</p>
			</div>
			<router-link to="/adhibition/active/edit">
				<a-button v-has="{action:'/adhibition/active/edit'}" type="primary">
					<i class="ri-add-line"></i>新增活动
				</a-button>
			</router-link>
		</div>
		<div class="el-content">
            <div class="kd-pacel">列表</div>
			<a-tabs v-model:activeKey="status" @change="getActiveList(1,info.limit)">
				<a-tab-pane key="1" tab="未开始"></a-tab-pane>
				<a-tab-pane key="2" tab="报名中"></a-tab-pane>
				<a-tab-pane key="3" tab="进行中"></a-tab-pane>
				<a-tab-pane key="4" tab="结束"></a-tab-pane>
				<a-tab-pane key="5" tab="待审核"></a-tab-pane>
			</a-tabs>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'活动',dataIndex:'info',slots:{customRender:'info'}},
				{title:'店铺信息',dataIndex:'shop',slots:{customRender:'shop'}},
				{title:'价格(元)',dataIndex:'price',slots:{customRender:'price'}},
				{title:'活动时间',dataIndex:'time',slots:{customRender:'time'}},
				{title:'状态展示',dataIndex:'status',slots:{customRender:'status'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #info="{record}">
					<div class="kd-activeList">
						<div style="width: 40px;height: 40px;margin-right: 10px;">
							<a-image  style="width: 40px;height: 40px;" :src="record.logo" />
						</div>
						<div class="kd-activeList-name">
							<div>{{record.title}}</div>
							   <div style="color: red;" v-if="record.is_draft == 1">[草稿]</div>
						</div>
					</div>
				</template>
				<template #shop="{record}">
					<template v-if="record.shop">
						<div><span class="f12">店铺名称：</span><a-tag color="#FF0099">{{record.shop.name}}</a-tag></div>
						<div><span class="f12">联系电话：</span>{{record.shop.mobile}}</div>
						<div><span class="f12">店铺id：</span>{{record.shop.mobile}}</div>
					</template>
					<template v-else>
						<a-tag color="#00CC66">自营</a-tag>
					</template>
				</template>
				<template #price="{record}">
					<span v-if="record.max_price > 0 ">￥{{record.min_price}}~￥{{record.max_price}}</span>
					<span v-else>免费</span>
				</template>
				<template #time="{record}">
					<div><span class="f12">开始时间：</span>{{record.activity_start_time}}</div>
					<div><span class="f12">结束时间：</span>{{record.activity_end_time}}</div>
				</template>
				<template #status="{record}">
					<div>
						<span class="f12">上架状态：</span>
						<a-tag 
						@click="handleActive(record.id,'is_put_away',record.is_put_away)" 
						:color="record.is_put_away ==1 ? '#00CC66':'#999'" >
						{{record.is_put_away==1 ? '上架':'已下架'}}
						</a-tag>
					</div>
					<div>
						<span class="f12">报名通道：</span>
						<a-tag @click="handleActive(record.id,'open_status',record.open_status)" :color="record.open_status ==1 ? '#0099FF':'#999'" >{{record.open_status ==1 ? '已开启':'未开启'}}</a-tag>
					</div>
					<div>
						<span class="f12">平台推广：</span>
						<a-tag color="#9966FF" v-if="record.is_recommend==1">推广</a-tag>
						<a-tag color="#999" v-if="record.is_recommend==0">不推广</a-tag>
					</div>
					<div>
						<span class="f12">审核状态：</span>
						<a-tag color="#999" v-if="record.status == 1">待审核</a-tag>
						<a-tag color="#00CC66" v-if="record.status == 2">已通过</a-tag>
						<a-tag color="#FF0066" v-if="record.status == 3">已拒绝</a-tag>
					</div>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:'/adhibition/active/edit',query:{id:record.id}}">
							<kd-button type="primary" 
								title="编辑" 
								icon="ri-edit-line" 
								v-has="{action:'/adhibition/active/edit'}">
							</kd-button>
						</router-link>
						<kd-button type="danger" 
							title="删除" 
							icon="ri-delete-bin-5-line" 
							v-has="{action:'wxapp_adhibition_active_del'}"  
							@click="deleteActive(record.id)">
						</kd-button>
						<router-link :to="{path:'/adhibition/active/ticket',query:{id:record.id}}">
							<kd-button type="purple" 
								title="票种信息设置" 
								icon="ri-coupon-line" 
								v-has="{action:'/adhibition/active/ticket'}">
							</kd-button>
						</router-link>
						<kd-button type="black" 
							title="报名信息设置" 
							icon="ri-file-list-2-line" 
							v-has="{action:'/adhibition/active/edit'}"  
							@click="showFormInfo(record)">
						</kd-button>
						<template v-if="record.status !=2 && record.is_draft != 1">
							<kd-button type="success" 
								title="通过审核" 
								icon="ri-check-double-line" 
								v-has="{action:'wxapp_adhibition_active_putaway'}"  
								@click="passCheck(record.id)">
							</kd-button>
							<kd-button type="danger" 
								title="驳回申请" 
								icon="ri-close-line" 
								v-has="{action:'wxapp_adhibition_active_putaway'}" 
								@click="rejectCheck(record.id)">
							</kd-button>
						</template>
					</a-space>
				</template>
			</a-table>
            <div class="pager">
            	<a-pagination
            		show-size-changer
            		:default-current="info.page"
            		:total="info.count"
            		@showSizeChange="(p,e)=>{getActiveList(info.page,e)}"
            		@change="(e)=>{getActiveList(e,info.limit)}"
            	/>
            </div>
			<a-modal v-model:visible="show.info" title="报名信息" :footer="null" width="600px" @cancel="show.info = false">
				<a-form ref="addForm" :model="infoForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
					<a-tag color="#2db7f5">姓名</a-tag>
					<a-tag color="#2db7f5">电话</a-tag>
					<a-tag style="margin-right: 10px;" :key="v.id"  v-for="v in formList" closable :disable-transitions="false" @close="handleClose(v.id)">
					   {{v.name}}
					</a-tag>
					<a-input
					    v-if="show.inputVisible"
					    ref="saveTagInput"
					    type="text"
					    v-model:value="infoForm.name"
					    @blur="handleInputConfirm"
					    @keyup.enter="handleInputConfirm"
					  />
					<a-tag v-else @click="showInput" style="background: #fff; border-style: dashed">
					    + 新增
					</a-tag>
				</a-form>
			</a-modal>
			<a-modal v-model:visible="show.reject" title="驳回申请" @ok="sureRejctCheck" width="600px" @cancel="show.reject = false">
				<a-textarea v-model:value="rejectForm.msg" placeholder="驳回理由" :rows="4" />
			</a-modal>
		</div>
	</div>
</template>

<script>
import { onActivated, reactive, toRefs } from 'vue'
import activeModel from '@/api/addons/active'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'

export default{
	name:"ActiveList",
	setup(){
		const state = reactive({
			status: '1',
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			rejectForm:{
				id:0,
				value:3,
				msg:''
			},
			show:{
				reject:false,
				info:false,
				inputVisible:false,
			},
			infoForm:{
				rid:0,
				name:'',
				is_require:0,	//1必填
				type:1,	//1输入框（目前只有输入框）
			},
			formList:[]
		})	
		onActivated(()=>{
			getActiveList(state.info.page,state.info.limit) 
		})
		
		getActiveList(1,state.info.limit)

		function getActiveList(page,limit){
			activeModel.getActive(page,limit,{status:state.status},res=>{
				state.info = {limit,...res}
			})
		}

		function handleActive(id,action,value){
			activeModel.handleActive(action,id,value == 1 ? 0 : 1,()=>getActiveList(state.info.page,state.info.limit))
		}

		function rejectCheck(id){
			state.rejectForm.id = id
			state.show.reject = true
		}

		function showFormInfo(row){
			state.infoForm.rid = row.id
			state.formList = row.forms
			state.show.info = true
		}

		function getAllForms(id){
			activeModel.getActiveForms(id,res=>state.formList = res)
		}

		// 删除活动
		const deleteActive = id=>activeModel.deleteActive(id,()=>getActiveList(state.info.page,state.info.limit))

		const passCheck = id=>activeModel.auditActive(id,2,()=>getActiveList(state.info.page,state.info.limit))
		const sureRejctCheck = ()=>activeModel.auditActive(state.rejectForm.id,3,()=>{
			state.show.reject = false
			getActiveList(state.info.page,state.info.limit) 
		})

		const handleClose = id=>activeModel.deleteActiveFroms(id,()=>{
			getAllForms(state.infoForm.rid)
			getActiveList(state.info.page,state.info.limit)
		})

		const showInput = ()=>state.show.inputVisible = true

		const handleInputConfirm = ()=>activeModel.addActiveForms(state.infoForm, ()=>{
			getAllForms(state.infoForm.rid)
			getActiveList(state.info.page,state.info.limit)
			state.infoForm.name = '';
		})

		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/adhibition/active/edit' ? 1 : 2
			if( to.path == '/adhibition/active/ticket' ) type = 1
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})

		return{
			...toRefs(state),
			getActiveList,
			deleteActive,
			handleActive,
			passCheck,
			rejectCheck,
			sureRejctCheck,
			showFormInfo,
			handleClose,
			showInput,
			handleInputConfirm
		}
	},
}
</script>

<style lang="scss">
    .kd-activeList{
    	width: 100%;
    	display: flex;

    	&-category{
    		color: red;
    	}

    	&-cover{
    		width: 70px;
    		height: 70px;
    	}

    }
</style>
